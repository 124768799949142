import React, { useCallback, useEffect, useState } from 'react';
import { NewOrder, BankCard } from 'src/Assets/Images';
import {
  ContentDisplayTiles,
  UHConfirmModal,
  Loader,
  MultiSelectChip,
  UHCalenderComp
} from 'src/components';
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core';
import {
  GET_FILTER_VALUES,
  HTTP_STATUSES,
  INVOICE_COUNT
} from 'src/Config/constant';
import { API_SERVICES } from 'src/Services';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchVal } from 'src/hooks/useSearchVal';
import { useDebounce } from 'src/hooks/useDebounce';
import VendorCreateModal from '../VendorManagement/VendorCreateModal';
import InvoiceManagementTable from './InvoiceManagementTable';
import CustomerVendorPayoutModal from '../WalletManagement/CustomerVendorPayoutModal';
import { useEdit } from 'src/hooks/useEdit';

const FILTER_CHIPS = ['Today', 'This Week', 'This Month', 'All'];

const useStyles = makeStyles((theme) => ({
  dividerStyle: {
    background: theme.Colors.black
  },
  containerStyle: {
    paddingRight: theme.spacing(2.8)
  },
  headingStyle: {
    paddingLeft: theme.spacing(2.5)
  }
}));

type Count = {
  customer_invoice: number;
  vendor_invoice: number;
};

function InvoiceManagement() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState<any>({ open: false });
  const [customerInvoiceCount, setCustomerInvoiceCount] = useState(0);
  const [vendorInvoiceCount, setVendorInvoiceCount] = useState(0);
  const [dateSelect, setDateSelect] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [calendarOpen, setCalendarOpen] = useState<any>({ open: false });

  const invoiceManagementDetailsTabValues = [
    {
      heading: t('InvoiceDetails.customerSales'),
      subText: customerInvoiceCount,
      iconImage: NewOrder,
      value: 1
    },
    {
      heading: t('InvoiceDetails.vendorSales'),
      subText: vendorInvoiceCount,
      iconImage: BankCard,
      value: 2
    }
  ];

  const [selectedTab, setSelectedTab] = useState<string | number>(
    invoiceManagementDetailsTabValues[0].value
  );
  const [invoiceManagementChipFilter, setInvoiceManagementChipFilter] =
    useState([FILTER_CHIPS[0]]);
  const [openVendorCreateModal, setOpenVendorCreateModal] = useState<any>({
    open: false
  });
  const [tableData, setTableData] = useState([]);
  const [tableVendorData, setTableVendorData] = useState([]);
  const [confirmModal, setConfirmModal] = useState<any>({
    open: false
  });
  const [vehicles, setVehicles] = useState([]);
  const { searchValue } = useSearchVal();
  const debValue = useDebounce(searchValue, 2000);

  const initialValue = {
    location: '',
    date: ''
  };

  const edit = useEdit(initialValue);

  const handleChangeVendorManagementChip = (selectedChipItem: string[]) => {
    setInvoiceManagementChipFilter(selectedChipItem);
  };
  const handleSetSelectedTab = (value) => {
    setSelectedTab(value);
  };

  const handleOpenCalendar = () => {
    setCalendarOpen({ open: true });
  };

  const handleClick = () => {
    const fromDate = dateSelect[0].startDate
      .toLocaleDateString('en-US')
      ?.split('/');
    const toDate = dateSelect[0].endDate
      .toLocaleDateString('en-US')
      ?.split('/');
    edit.update({
      date: `${fromDate[1]}/${fromDate[0]}/${fromDate[2]} to ${toDate[1]}/${toDate[0]}/${toDate[2]}`
    });
    setCalendarOpen({ open: false });
  };

  const handleChange = useCallback(
    (item) => {
      setDateSelect([item.selection]);
    },
    [dateSelect]
  );

  const fetchData = useCallback(async () => {
    try {
      let params: any = {};
      if (debValue !== '') {
        params.searchString = debValue;
      }

      const searchData = {
        searchString: edit.getValue('location'),
        toDate: edit.getValue('date').split('to')[1],
        fromDate: edit.getValue('date').split('to')[0],
        ...params
      };
      const response: any = await Promise.all([
        API_SERVICES.InvoiceManagementService.getCustomerInvoice(
          GET_FILTER_VALUES[invoiceManagementChipFilter[0]],
          searchData
        ),
        API_SERVICES.InvoiceManagementService.getVendorInvoice(
          GET_FILTER_VALUES[invoiceManagementChipFilter[0]],
          searchData
        )
      ]);
      if (response[0]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[0]?.data?.data?.length) {
          setTableData(response[0]?.data?.data);
          setCustomerInvoiceCount(response[0]?.data?.customersCount);
        }
      }
      if (response[1]?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response[1]?.data?.data?.length) {
          setTableVendorData(response[1]?.data?.data);
          setVendorInvoiceCount(response[1]?.data?.vendorsCount);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, [invoiceManagementChipFilter, debValue, edit.edits]);

  const handleViewDetails = async (rowData: any) => {
    try {
      const response: any =
        rowData?.type == 1
          ? await API_SERVICES.customerOrderService.getAcceptedOrder(
              rowData.order_no
            )
          : await API_SERVICES.vendorOrderService.getById(rowData?.order_no);
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.order) {
          setModalOpen({
            open: true,
            rowData: { ...rowData, ...response?.data?.order },
            isVendor: rowData?.type === 1 ? true : false,
            buttonName: 'Download Invoice'
          });
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await API_SERVICES.generalService.getVehicles();
      if (response?.status <= HTTP_STATUSES.BAD_REQUEST) {
        if (response?.data?.vehicles) {
          setVehicles(response.data.vehicles);
        }
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClickDownload = async (rowData: {
    invoice_url: RequestInfo | URL;
  }) => {
    try {
      const url = `${rowData?.invoice_url}?timestamp=${Date.now()}`;
      console.log('URL:', url);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/octet-stream'
        }
      });

      console.log('Response:', response);
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      console.log('Blob size:', blob.size, 'Blob type:', blob.type);

      // Extract the filename from the URL
      const fileName =
        rowData?.invoice_url.toString().split('/').pop()?.split('?')[0] ||
        'downloaded-file';

      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName; // Use the extracted filename
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl); // Clean up
    } catch (error) {
      console.log('There was an error downloading the file:', error);
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        <Grid container>
          <Grid className={classes.containerStyle} item>
            <MultiSelectChip
              chipItems={FILTER_CHIPS}
              selectedChipItem={invoiceManagementChipFilter}
              handleChange={handleChangeVendorManagementChip}
              chipStyle={{
                //cannot apply makestyle
                padding: theme.spacing(2, 0.8),
                height: theme.MetricsSizes.large
              }}
            />
          </Grid>
        </Grid>
        <ContentDisplayTiles
          displayContent={invoiceManagementDetailsTabValues}
          isTileTypeOrders={true}
          onTabChange={handleSetSelectedTab}
          tabValue={selectedTab}
        />
        <Box sx={{ mt: 3 }}>
          <InvoiceManagementTable
            handleViewDetails={handleViewDetails}
            tableRowData={selectedTab == 1 ? tableData : tableVendorData}
            handleClickDownload={handleClickDownload}
            edit={edit}
            handleOpenCalendar={handleOpenCalendar}
          />
        </Box>
        {calendarOpen.open ? (
          <UHCalenderComp
            onCancelClick={() => setCalendarOpen({ open: false })}
            handleChange={handleChange}
            ranges={dateSelect}
            onConfirmClick={handleClick}
            {...calendarOpen}
          />
        ) : null}
        {modalOpen.open && (
          <CustomerVendorPayoutModal
            onClose={() => setModalOpen({ open: false })}
            {...modalOpen}
            handleClickPayout={handleClickDownload}
          />
        )}
        {openVendorCreateModal.open && (
          <VendorCreateModal
            onClose={() => setOpenVendorCreateModal({ open: false })}
            updateData={fetchData}
            vehicleData={vehicles}
            {...openVendorCreateModal}
          />
        )}
        {confirmModal.open && <UHConfirmModal {...confirmModal} />}
      </>
    );
  }
}

export default InvoiceManagement;
