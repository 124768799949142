import React, { useEffect, useState } from 'react';
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Downloading } from 'src/Assets';
import { ButtonComp, DialogComp, DialogContentDetails } from 'src/components';
import {
  capitalizeFirstLetter,
  dateFormate,
  getSortMonth,
  getToday
} from 'src/Utils';
import CustomerVendorImageBox from './CustomerVendorImageBox';
import { useTranslation } from 'react-i18next';
import { API_SERVICES } from 'src/Services';
import { HTTP_STATUSES } from 'src/Config/constant';

const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogPaper: {
      width: 847,
      height: 705,
      padding: theme.spacing(2, 3, 0, 6),
      borderRadius: theme.MetricsSizes.regular
    },
    imgStyle: {
      borderRadius: theme.MetricsSizes.small_x,
      width: '320px',
      height: '200px'
    },
    headingStyle: {
      color: '#333333',
      fontSize: 24,
      fontWeight: theme.fontWeight.bold,
      opacity: 0.5,
      textAlign: 'center',
      paddingRight: 5
    }
  };
});

type Props = {
  onClose: () => void;
  rowData: any;
  isVendor?: boolean;
  buttonName?: string;
  handleClickPayout?: (data?: any, isVendor?: boolean) => void;
  isPayoutButton?: boolean;
};

const CustomerVendorPayoutModal = ({
  onClose,
  rowData,
  isVendor,
  buttonName,
  handleClickPayout,
  isPayoutButton
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [accDetails, setAccDetails] = useState<any>([]);

  const getSlot = (val) => {
    if (val.toLowerCase() == 'morning') {
      return '9:00 AM to 12:00 PM';
    } else if (val.toLowerCase() == 'afternoon') {
      return '1:00 PM to 4:00 PM';
    } else {
      return '4:00 PM to 7:00 PM';
    }
  };

  const getBankAccountDetails = async () => {
    try {
      setAccDetails([]);
      const response: any = isVendor
        ? await API_SERVICES.customerVendorListService.getVendorBankDetailsById(
            rowData?.vendor_id
          )
        : await API_SERVICES.customerVendorListService.getCustomerBankDetailById(
            rowData?.customer_id
          );
      if (response.status === HTTP_STATUSES.OK) {
        setAccDetails(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBankAccountDetails();
  }, [isVendor]);

  const renderDialogContent = () => {
    const day = getToday(rowData?.pickup_time);
    const d = dateFormate(rowData?.pickup_time)?.split('-');
    const month = getSortMonth(d[1]);

    const contentDetails = [
      {
        content: 'Order Id',
        value: rowData?.order_id || rowData?.id
      },
      {
        content: t('address'),
        value: `${rowData?.address_line1}, ${rowData?.address_line2}, ${rowData?.address_line3}, ${rowData?.city}, ${rowData?.state}, ${rowData?.pincode}`
      },
      {
        content: t('contactNumber'),
        value: rowData?.order_mobile_number || rowData?.registered_mobile_number
      },
      {
        content: 'Scheduled On',
        value: `${day}, ${d[0]} ${month},  ${getSlot(rowData?.slot)}`
      }
    ];

    const bankDetails = [
      {
        content: 'Bank Name',
        value: accDetails[0]?.name
      },
      {
        content: 'Account Number',
        value: accDetails[0]?.account_number
      },
      {
        content: 'IFSC Code',
        value: accDetails[0]?.ifsc_code
      }
    ];
    console.log('rowData',rowData);
    
    return (
      <Grid>
        <DialogContentDetails contentDetails={contentDetails} />
        <Grid container style={{ paddingTop: 20, paddingBottom: 10, gap: 10 }}>
          <Typography className={classes.headingStyle}>
            Account Details
          </Typography>
          <Typography className={classes.headingStyle}>-</Typography>
          <Typography className={classes.headingStyle}>Bank Account</Typography>
        </Grid>
        <DialogContentDetails contentDetails={bankDetails} />
        <CustomerVendorImageBox data={rowData} isVendor={isVendor} />
      </Grid>
    );
  };

  const renderAction = () => {
    return (
      <Grid container justifyContent="center">
        {isPayoutButton ? (
          <ButtonComp
            backgroundColor={theme.Colors.primary}
            height={'35px'}
            buttonText={buttonName || 'Payout'}
            buttonFontSize={theme.MetricsSizes.small_x}
            buttonTextColor={theme.Colors.white}
            buttonFontWeight={theme.fontWeight.bold}
            btnWidth={buttonName ? '235px' : '131px'}
            btnBorderRadius={100}
            onClickButton={() => handleClickPayout(rowData, isVendor)}
            style={{ margin: 10 }}
            startIcon={buttonName ? <img src={Downloading} /> : ''}
          />
        ) : null}
      </Grid>
    );
  };
  return (
    <DialogComp
      dialogTitle={capitalizeFirstLetter(rowData?.company_name)}
      avatarImg={rowData?.image_url || 'DustMan'}
      open={true}
      onClose={onClose}
      dialogClasses={{ paper: classes.dialogPaper }}
      renderDialogContent={renderDialogContent}
      renderAction={renderAction}
    />
  );
};

export default CustomerVendorPayoutModal;
